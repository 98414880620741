<!--
 * @Author: your name
 * @Date: 2020-12-21 17:32:34
 * @LastEditTime: 2021-05-31 15:30:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\htmlpay\index.vue
-->
<template>
  <div></div>
</template>
<script>
export default {
  methods: {
    htmlpay(
      WIDtotal_amount,
      order_type,
      url,
      panment = "2",
      WIDsubject = "购买套餐",
      WIDbody = "描述",
      isdetailspay = "" //是否是从《充值记录》菜单进行充值
    ) {
      let uppayobj = "";
      if (isdetailspay == "true") {
        uppayobj = {
          reqType: "cost",
          order_no: this.$route.query.order_no,
        };
      } else {
        uppayobj = {
          reqType: "cost",
          WIDsubject: WIDsubject,
          WIDtotal_amount: WIDtotal_amount,
          // WIDtotal_amount: "0.01",
          WIDbody: WIDbody,
          panment: panment,
          order_type: order_type, //	订单类型 1.充值、2.购买。3、购买套餐、4.活动购买、5.购买套餐包、6.请求报告、7.批量购买、8.自查购买、9.退款
          id: this.$route.query.id,
        };
      }

      // this.fff = uppayobj
      let httpurl = "";
      if (isdetailspay == "true") {
        httpurl = "/firm/v1/cost/wechatPhonePay3";
      } else {
        httpurl = "/firm/v1/Login/wechatPhonePay2";
      }
      this.$http
        .post(httpurl, uppayobj)
        .then((res) => {
          // url:代表回调地址，支付成功后是否需要跳转到新地址
          if (url == "" || url == undefined) {
            window.location.href = JSON.parse(res.data).data.mweb_url;
          } else {
            window.location.href =
              JSON.parse(res.data).data.mweb_url +
              "&redirect_url=" +
              encodeURIComponent(url);
          }
        })
        .catch((res) => {});
    },
  },
  async mounted() {
    /**
     * lock:防止重复支付
     */

    await this.$toast.loading("请稍后");
    await this.htmlpay(
      this.$route.query.money,
      this.$route.query.orderType,
      this.$route.query.url,
      this.$route.query.panment, //选填：默认就是2 具体有api文档，这步基本上不需要修改
      this.$route.query.WIDsubject, //选填：默认是购买套餐
      this.$route.query.WIDbody, //选填：默认是描述
      this.$route.query.isdetailspay //选填：//是否是从《充值记录》菜单进行充值
    );
  },
};
</script>
<style scoped></style>
